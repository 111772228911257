import {
   Button,
   List,
   ListItem,
   Modal,
   ModalContent,
   ModalOverlay,
   Text,
   useDisclosure,
} from '@chakra-ui/react';
import { NextImage } from '@innerwell/chakra/images';
import { WelkinPrograms } from '@innerwell/dtos';
import { isGeneralPsychiatryProgram } from '@innerwell/phaseUtils';
import { useEffect, useMemo } from 'react';

import { usePatientProgram } from '@/contexts/patient-program-context';
import { useSession } from '@/contexts/session-context';

import { Icon } from '../Icon';
import { Illustration } from '../Illustration';

type Props = {
   onCloseComplete?: () => void;
};

export const WelcomeModal = ({ onCloseComplete }: Props) => {
   const { data: sessionData } = useSession();
   const { isOpen, onOpen, onClose } = useDisclosure();
   const {
      programPhase: { program },
   } = usePatientProgram();

   const list = useMemo(() => {
      if (
         isGeneralPsychiatryProgram(program) ||
         program === WelkinPrograms.Therapy
      ) {
         return [
            'Schedule appointments with your Care Team.',
            'Track your progress with daily mood monitoring and weekly symptom tracking.',
            'Focus your mind with guided meditations from Zen Master Henry Shukman.',
            'Securely message your Care Team and Member Support.',
         ];
      }

      return [
         'Schedule appointments with your Care Team.',
         'Prepare for and start ketamine experiences safely.',
         'Track your progress with daily mood monitoring and weekly symptom tracking.',
         'Focus your mind with guided meditations from Zen Master Henry Shukman.',
         'Securely message your Care Team and Member Support.',
      ];
   }, [program]);

   useEffect(() => {
      onOpen();
   }, [onOpen]);

   const handleCloseComplete = () => {
      if (onCloseComplete) {
         onCloseComplete();
      }
   };

   return (
      <Modal
         isOpen={isOpen}
         onClose={onClose}
         isCentered
         closeOnEsc={false}
         closeOnOverlayClick={false}
         onCloseComplete={handleCloseComplete}
         size="inside"
      >
         <ModalOverlay />
         <ModalContent
            borderRadius={12}
            textAlign="center"
            overflow={{ base: 'auto', lg: 'hidden' }}
         >
            <Illustration
               src="/illustrations/three-circles-top-right.svg"
               pos="absolute"
               right={0}
               top={0}
            />

            <NextImage
               w={{ base: '40px', lg: '60px' }}
               h={{ base: '40px', lg: '60px' }}
               src="/images/ben-medrano-peach.jpg"
               alt="Ben Medrano"
               mb={2}
               borderRadius="50%"
            />
            <Text size="leadTitle" fontWeight={500} mb={2}>
               Glad to have you with us, {sessionData?.given_name}
            </Text>
            <Text
               size="paragraph"
               opacity={0.6}
               fontWeight={500}
               px={{ base: 8, lg: 14 }}
               mb={6}
            >
               Welcome to the Innerwell Portal, everything you need to:
            </Text>

            <List
               spacing={2}
               fontSize="xs"
               textAlign="left"
               mb={9}
               px={{ base: 6, lg: 0 }}
            >
               {list.map((item) => (
                  <ListItem
                     key={item}
                     color="background.tertiary"
                     pos="relative"
                     pl={6}
                  >
                     <Icon
                        name="checkmark"
                        w={4}
                        h={4}
                        pos="absolute"
                        color="background.primary"
                        left={0}
                        top={1}
                     />

                     {item}
                  </ListItem>
               ))}
            </List>

            <Button
               onClick={onClose}
               rightIcon={<Icon name="chevron-right" w={5} h={5} />}
               mb={{ base: 4, lg: 6 }}
               size={{ base: 'sm', lg: 'md' }}
            >
               Start exploring
            </Button>
         </ModalContent>
      </Modal>
   );
};
