import {
   AccordionButton,
   AccordionItem,
   AccordionPanel,
   Badge,
   Flex,
   FlexProps,
   Text,
   Tooltip,
} from '@chakra-ui/react';
import {
   type AppointmentItem as Appointment,
   WelkinEncounterTemplateNames,
} from '@innerwell/dtos';
import { Route } from 'next';

import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

import { Icon } from '../Icon';

const appointmentDescriptionMap = new Map<
   Appointment['appointmentTemplate'],
   string
>([
   [
      WelkinEncounterTemplateNames.MedicalConsult,
      'Select your Psychiatric Clinician from our team and book an appointment to develop a personalized treatment plan. They will be with you every step of the way at Innerwell.',
   ],
   [
      WelkinEncounterTemplateNames.MedicalFollowUp,
      'Schedule a session with your Psychiatric Clinician to go over your ketamine treatment and make any necessary dosage adjustments for optimal progress.',
   ],
   [
      WelkinEncounterTemplateNames.CapstoneAppointment,
      'Acknowledge the inner work you’ve done recently. Your final appointment with your Psychiatric Clinician will review your progress and plan your next steps.',
   ],
]);

type AppointmentBankItemProps<THref extends string> = {
   appointment: {
      name: string;
      purchaseUrl?: Route<THref>;
      schedulingUrl: Route<THref>;
      availableCount: number;
      appointmentTemplate: Appointment['appointmentTemplate'];
   };
   isLocked?: boolean;
   // Note shown when scheduling button is disabled
   schedulingNote?: string | null;
} & FlexProps;

export function AppointmentBankItem<T extends string>({
   appointment,
   isLocked,
   schedulingNote,
   ...flexProps
}: AppointmentBankItemProps<T>) {
   const {
      name,
      availableCount,
      schedulingUrl,
      purchaseUrl,
      appointmentTemplate,
   } = appointment;

   const description = appointmentDescriptionMap.get(appointmentTemplate);

   const isCollapsible = Boolean(description);

   return (
      <AccordionItem
         border="none"
         borderRadius="12px"
         boxShadow="0px 5px 5px rgba(0, 0, 0, 0.15)"
      >
         {({ isExpanded }) => (
            <>
               <AccordionButton
                  p={{ base: 3, lg: 4 }}
                  bg="linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%), #FFFFFF"
                  _hover={{
                     bg: 'linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%), #FFFFFF',
                  }}
                  borderRadius="12px"
                  transition="all .15s ease"
                  borderBottomLeftRadius={
                     isExpanded && isCollapsible ? 0 : '12px'
                  }
                  borderBottomRightRadius={
                     isExpanded && isCollapsible ? 0 : '12px'
                  }
                  pos="relative"
                  cursor={isCollapsible ? 'pointer' : 'default'}
               >
                  <Flex {...flexProps} flexDir="column" flex={1}>
                     <Flex alignItems="center" flex={1}>
                        <Badge
                           fontWeight={600}
                           textAlign="center"
                           px={{ base: 2, lg: 4 }}
                           borderRadius="12px"
                           bg="accent.peach"
                           color="accent.orangeDark"
                        >
                           {availableCount} left
                        </Badge>
                        <Flex
                           ml={2}
                           flex={1}
                           flexDir="column"
                           textAlign="left"
                           pr={8}
                           color="text.primary"
                        >
                           {name}
                        </Flex>

                        <Tooltip
                           textColor="white"
                           label={schedulingNote}
                           shouldWrapChildren
                           hasArrow
                           bg="text.primary"
                           borderRadius="8px"
                           maxW="260px"
                           py={2}
                           ml={{ base: 2, lg: 0 }}
                           px={{ base: 2, lg: 3 }}
                           fontWeight={500}
                           isDisabled={!isLocked || !schedulingNote}
                        >
                           {availableCount > 0 ? (
                              <NextLinkButton
                                 display={{ base: 'none', md: 'inline-flex' }}
                                 ml="auto"
                                 size="xs"
                                 href={schedulingUrl}
                                 maxW="150px"
                                 isDisabled={isLocked}
                                 icon={
                                    <Icon
                                       name="calendar-schedule"
                                       w={4}
                                       h={4}
                                       color="white"
                                    />
                                 }
                                 mr={9}
                              >
                                 Schedule
                              </NextLinkButton>
                           ) : purchaseUrl ? (
                              <NextLinkButton
                                 display={{ base: 'none', md: 'inline-flex' }}
                                 ml="auto"
                                 size="xs"
                                 maxW="150px"
                                 mr={9}
                                 href={purchaseUrl}
                              >
                                 Purchase
                              </NextLinkButton>
                           ) : null}
                        </Tooltip>
                        {isCollapsible && (
                           <Icon
                              name="chevron-down-light"
                              w={5}
                              h={5}
                              color="text.primary"
                              ml="auto"
                              transition="transform 0.2s"
                              transform={
                                 isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
                              }
                              pos="absolute"
                              right={{ base: 3, lg: 4 }}
                           />
                        )}
                     </Flex>

                     <Tooltip
                        textColor="white"
                        label={schedulingNote}
                        shouldWrapChildren
                        hasArrow
                        bg="text.primary"
                        borderRadius="8px"
                        maxW="260px"
                        py={2}
                        ml={{ base: 2, lg: 0 }}
                        px={{ base: 2, lg: 3 }}
                        fontWeight={500}
                        isDisabled={!isLocked || !schedulingNote}
                     >
                        {availableCount > 0 ? (
                           <NextLinkButton
                              display={{ base: 'flex', md: 'none' }}
                              mt={4}
                              size="sm"
                              href={schedulingUrl}
                              isDisabled={isLocked}
                              icon={
                                 <Icon
                                    name="calendar-schedule"
                                    w={4}
                                    h={4}
                                    color="white"
                                 />
                              }
                           >
                              Schedule
                           </NextLinkButton>
                        ) : purchaseUrl ? (
                           <NextLinkButton
                              display={{ base: 'flex', md: 'none' }}
                              mt={4}
                              size="sm"
                              href={purchaseUrl}
                           >
                              Purchase
                           </NextLinkButton>
                        ) : null}
                     </Tooltip>
                  </Flex>
               </AccordionButton>

               {isCollapsible && (
                  <AccordionPanel
                     py={{ base: 4, lg: 7 }}
                     px={{ base: 4, lg: 6 }}
                     bg="accent.sand"
                     borderBottomLeftRadius={'12px'}
                     borderBottomRightRadius={'12px'}
                     color="text.primary"
                  >
                     <Text>{description}</Text>
                  </AccordionPanel>
               )}
            </>
         )}
      </AccordionItem>
   );
}
