import { PatientLastChosenCarePlanDto } from '@innerwell/dtos';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';
import { useSession } from '@/contexts/session-context';

import { queryKeys } from '@/types/query-keys';

export default function usePatientLastCarePlanChoice(
   opts: Omit<
      UseQueryOptions<PatientLastChosenCarePlanDto | null>,
      'queryKey' | 'queryFn' | 'initialData'
   > = {},
) {
   const { data: sessionData } = useSession();
   const { data: plan, ...rest } = useQuery({
      queryKey: queryKeys.patientLastCarePlanChoice,
      queryFn: async () => {
         const response =
            await webApiClient.patients.getLastPatientPlanChoice();
         return response.body;
      },
      enabled: !!sessionData,
      ...opts,
   });

   return {
      ...rest,
      chosenCarePlan: plan?.chosenCarePlan ?? null,
   };
}
