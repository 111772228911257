import {
   AccordionButton,
   AccordionItem,
   AccordionPanel,
   Badge,
   Divider,
   Flex,
   Text,
   Tooltip,
} from '@chakra-ui/react';
import {
   InsuranceAvailableAddonForPurchase,
   WelkinEncounterTemplateNames,
} from '@innerwell/dtos';
import { Route } from 'next';

import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

import { useScheduling } from '@/contexts/scheduling-context';
import { generateSchedulingLink } from '@/utils';

import { Icon } from '../Icon';

const AddonItem = ({
   addon,
   isLocked,
   isExpanded,
}: {
   addon: InsuranceAvailableAddonForPurchase;
   isLocked?: boolean;
   isExpanded?: boolean;
}) => {
   const {
      encounterBank: { left },
      encounterName,
      purchaseCategoryId,
      name,
      sku,
   } = addon;

   const {
      online: { location },
   } = useScheduling();

   const purchaseUrl =
      `/purchase/addons/${String(purchaseCategoryId)}/${sku}` satisfies Route<`/purchase/addons/${string}/${string}`>;
   const schedulingUrl = generateSchedulingLink(encounterName, location);

   return (
      <Flex flexDir="column" flex={1}>
         <Flex alignItems="center" flex={1}>
            <Badge
               fontWeight={600}
               textAlign="center"
               px={{ base: 2, lg: 4 }}
               borderRadius="12px"
               bg="accent.peach"
               color="accent.orangeDark"
            >
               {left} left
            </Badge>
            <Flex
               ml={2}
               flex={1}
               flexDir="column"
               textAlign="left"
               pr={8}
               color="text.primary"
            >
               {name}
            </Flex>

            <Tooltip
               textColor="white"
               label="You must complete the Meet Your Therapist appointment first."
               shouldWrapChildren
               hasArrow
               bg="text.primary"
               borderRadius="8px"
               maxW="260px"
               py={2}
               ml={{ base: 2, lg: 0 }}
               px={{ base: 2, lg: 3 }}
               fontWeight={500}
               isDisabled={!isLocked}
            >
               {left > 0 ? (
                  <NextLinkButton
                     display={{ base: 'none', md: 'inline-flex' }}
                     ml="auto"
                     size="xs"
                     href={schedulingUrl}
                     maxW="150px"
                     isDisabled={isLocked}
                     icon={
                        <Icon
                           name="calendar-schedule"
                           w={4}
                           h={4}
                           color="white"
                        />
                     }
                     mr={9}
                  >
                     Schedule
                  </NextLinkButton>
               ) : (
                  <NextLinkButton
                     display={{ base: 'none', md: 'inline-flex' }}
                     ml="auto"
                     size="xs"
                     maxW="150px"
                     mr={9}
                     href={purchaseUrl}
                  >
                     Purchase
                  </NextLinkButton>
               )}
            </Tooltip>
            <Icon
               name="chevron-down-light"
               w={5}
               h={5}
               color="text.primary"
               ml="auto"
               transition="transform .2s ease"
               transform={isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
               pos="absolute"
               right={{ base: 3, lg: 4 }}
            />
         </Flex>

         <Tooltip
            textColor="white"
            label="You must complete the Meet Your Therapist appointment first."
            shouldWrapChildren
            hasArrow
            bg="text.primary"
            borderRadius="8px"
            maxW="260px"
            py={2}
            ml={{ base: 2, lg: 0 }}
            px={{ base: 2, lg: 3 }}
            fontWeight={500}
            isDisabled={!isLocked}
         >
            {left > 0 ? (
               <NextLinkButton
                  display={{ base: 'flex', md: 'none' }}
                  mt={4}
                  size="sm"
                  href={schedulingUrl}
                  isDisabled={isLocked}
                  icon={
                     <Icon name="calendar-schedule" w={4} h={4} color="white" />
                  }
               >
                  Schedule
               </NextLinkButton>
            ) : (
               <NextLinkButton
                  display={{ base: 'flex', md: 'none' }}
                  mt={4}
                  size="sm"
                  href={purchaseUrl}
               >
                  Purchase
               </NextLinkButton>
            )}
         </Tooltip>
      </Flex>
   );
};

const InsuranceItem = ({
   addon,
   isExpanded,
   isLocked,
}: {
   addon: InsuranceAvailableAddonForPurchase;
   isExpanded?: boolean;
   isLocked?: boolean;
   // isSchedulingDisabled?: boolean;
}) => {
   const {
      name,
      encounterName,
      purchaseCategoryId,
      encounterBank: { left },
      countInProgress,
   } = addon;

   const scheduleUrl =
      `/schedule/${encounterName}` satisfies Route<`/schedule/${string}`>;
   const purchaseUrl =
      `/purchase/addons?cat=${purchaseCategoryId}` satisfies Route;
   const showBuyMore =
      left === 0 &&
      encounterName === WelkinEncounterTemplateNames.SupervisedDosingALaCarte;
   return (
      <Flex flexDir="column" flex={1}>
         <Flex alignItems="center" flex={1}>
            {showBuyMore && left === 0 ? (
               <Badge
                  fontWeight={600}
                  textAlign="center"
                  px={{ base: 2, lg: 4 }}
                  borderRadius="12px"
                  bg="accent.peach"
                  color="accent.orangeDark"
               >
                  0 left
               </Badge>
            ) : null}
            <Flex
               ml={2}
               flex={1}
               flexDir="column"
               textAlign="left"
               pr={8}
               color="text.primary"
            >
               {name}
            </Flex>

            {showBuyMore ? (
               <NextLinkButton
                  display={{
                     base: 'none',
                     md: 'inline-flex',
                  }}
                  variant="outline"
                  ml="auto"
                  size="xs"
                  href={purchaseUrl}
                  maxW="150px"
                  mr={9}
               >
                  Purchase now
               </NextLinkButton>
            ) : (
               <NextLinkButton
                  display={{
                     base: 'none',
                     md: 'inline-flex',
                  }}
                  ml="auto"
                  size="xs"
                  href={scheduleUrl}
                  maxW="170px"
                  icon={
                     <Icon name="calendar-schedule" w={4} h={4} color="white" />
                  }
                  mr={9}
                  isDisabled={isLocked || countInProgress > 0}
               >
                  Schedule now
               </NextLinkButton>
            )}
            <Icon
               name="chevron-down-light"
               w={5}
               h={5}
               color="text.primary"
               ml="auto"
               transition="transform .2s ease"
               transform={isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
               pos="absolute"
               right={{ base: 3, lg: 4 }}
            />
         </Flex>
         <Divider
            display={{ base: 'block', md: 'none' }}
            my={4}
            borderColor="background.primary"
            opacity={0.1}
         />

         {showBuyMore ? (
            <NextLinkButton
               display={{ base: 'flex', md: 'none' }}
               variant="outline"
               size="xs"
               href={purchaseUrl}
            >
               Purchase now
            </NextLinkButton>
         ) : (
            <NextLinkButton
               display={{ base: 'flex', md: 'none' }}
               size="xs"
               href={scheduleUrl}
               icon={
                  <Icon name="calendar-schedule" w={4} h={4} color="white" />
               }
               isDisabled={isLocked || countInProgress > 0}
            >
               Schedule now
            </NextLinkButton>
         )}
      </Flex>
   );
};

type Props = {
   addon: InsuranceAvailableAddonForPurchase;
   isInsurance?: boolean;
};

export const AvailableAppointmentBankItem = ({ addon, isInsurance }: Props) => {
   const { description } = addon;

   const footerDisclaimerText =
      addon.encounterName ===
         WelkinEncounterTemplateNames.SupervisedDosingALaCarte &&
      isInsurance &&
      addon.encounterBank.left === 0
         ? `$${addon.price.toFixed(0)} Non-Covered Service Fee`
         : null;
   return (
      <AccordionItem
         border="none"
         borderRadius="12px"
         boxShadow="0px 5px 5px rgba(0, 0, 0, 0.15)"
      >
         {({ isExpanded }) => (
            <>
               <AccordionButton
                  p={{ base: 3, lg: 4 }}
                  bg="linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%), #FFFFFF"
                  _hover={{
                     bg: 'linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%), #FFFFFF',
                  }}
                  borderRadius="12px"
                  transition="all .15s ease"
                  borderBottomLeftRadius={
                     isExpanded || footerDisclaimerText ? 0 : '12px'
                  }
                  borderBottomRightRadius={
                     isExpanded || footerDisclaimerText ? 0 : '12px'
                  }
                  pos="relative"
               >
                  {isInsurance ? (
                     <InsuranceItem
                        addon={addon}
                        isExpanded={isExpanded}
                        isLocked={!addon.canBeScheduled}
                     />
                  ) : (
                     <AddonItem
                        addon={addon}
                        isExpanded={isExpanded}
                        isLocked={!addon.canBeScheduled}
                     />
                  )}
               </AccordionButton>

               <AccordionPanel
                  py={{ base: 4, lg: 7 }}
                  px={{ base: 4, lg: 6 }}
                  bg="accent.sand"
                  borderBottomLeftRadius={footerDisclaimerText ? 0 : '12px'}
                  borderBottomRightRadius={footerDisclaimerText ? 0 : '12px'}
                  color="text.primary"
               >
                  {description ? (
                     <Text dangerouslySetInnerHTML={{ __html: description }} />
                  ) : null}
               </AccordionPanel>

               {footerDisclaimerText ? (
                  <Flex
                     bg="background.primary"
                     px={{ base: 4, lg: 6 }}
                     py={1.5}
                     borderBottomRadius={12}
                  >
                     <Text
                        size="paragraphSmallest"
                        fontWeight={500}
                        w="full"
                        textAlign="center"
                     >
                        {footerDisclaimerText}
                     </Text>
                  </Flex>
               ) : null}
            </>
         )}
      </AccordionItem>
   );
};
