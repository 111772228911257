import { Box, Flex } from '@chakra-ui/react';
import { SharedProgramPhases } from '@innerwell/dtos';

import { useNextProgramEncounterToSchedule } from '@/hooks/react-query/useNextProgramEncounterToSchedule';
import useUpcomingAppointments from '@/hooks/react-query/useUpcomingAppointments';

import { useUserAccess } from '@/components/Cards/PatientLocked/useUserAccess';

import { usePatientProgram } from '@/contexts/patient-program-context';

import { AppointmentBank } from '../AppointmentBank/AppointmentBank';
import {
   MissedAppointmentPaymentCompletedCard,
   StartKetamineExperienceCard,
} from '../Cards';
import { PatientLocked } from '../Cards/PatientLocked/PatientLocked';
import ScheduleNextAppointmentCard from '../Cards/ScheduleNextAppointmentCard';
import { DividerWithText } from '../Dividers';
import HomeWeeklyMoodTrackingStep from '../HomeSteps/HomeWeeklyMoodTrackingStep';
import { SkeletonWithLoader } from '../Loaders';
import { UpcomingAppointments } from '../Swipers/UpcomingAppointments';

export const ExtendedLayout = () => {
   const {
      programPhase: { phase },
      isCurrentlyInKetamineProgram,
   } = usePatientProgram();

   const { isLocked, statuses } = useUserAccess();

   const { appointments: upcomingAppointments, status: appointmentsStatus } =
      useUpcomingAppointments();

   const { nextAppointment } = useNextProgramEncounterToSchedule();

   return (
      <Box pb="30px">
         {phase === SharedProgramPhases.MissedAppointmentPaymentCompleted && (
            <MissedAppointmentPaymentCompletedCard mb={6} />
         )}

         <HomeWeeklyMoodTrackingStep />

         {isCurrentlyInKetamineProgram && <StartKetamineExperienceCard />}

         {isLocked ? (
            <PatientLocked statuses={statuses} />
         ) : (
            <Flex flexDir="column" gap={4}>
               {nextAppointment && (
                  <ScheduleNextAppointmentCard appointment={nextAppointment} />
               )}

               <DividerWithText text="Your upcoming appointments" />

               <SkeletonWithLoader
                  loadingText="Loading upcoming appointments..."
                  minH={
                     appointmentsStatus === 'pending' ||
                     (upcomingAppointments && upcomingAppointments.length)
                        ? '56px'
                        : 0
                  }
                  borderRadius="12px"
                  isLoaded={appointmentsStatus === 'success'}
               >
                  <UpcomingAppointments appointments={upcomingAppointments} />
               </SkeletonWithLoader>

               <AppointmentBank />
            </Flex>
         )}
      </Box>
   );
};
