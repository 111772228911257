import {
   Accordion,
   Badge,
   Box,
   Button,
   Divider,
   Flex,
   Tab,
   TabList,
   TabPanel,
   TabPanels,
   Tabs as ChakraTabs,
   Text,
} from '@chakra-ui/react';
import { InsuranceAvailableAddonForPurchase } from '@innerwell/dtos';
import { useMemo, useState } from 'react';

import useCanBuyFollowOns from '@/hooks/react-query/useCanBuyFollowOns';
import { useInsuranceEncounters } from '@/hooks/react-query/useInsuranceEncounters';
import { useProgramEncounters } from '@/hooks/react-query/useProgramEncounters';

import { NonKetamineDisclaimer } from '@/components/Cards/NonKetamineDisclaimer';
import { SkeletonWithLoader } from '@/components/Loaders';

import { usePatientProgram } from '@/contexts/patient-program-context';
import { useScheduling } from '@/contexts/scheduling-context';
import { useSession } from '@/contexts/session-context';
import { generateSchedulingLink } from '@/utils';

import { AppointmentBankItem } from './AppointmentBankItem';
import { AvailableAppointmentBankItem } from './AvailableAppointmentBankItem';
import { PurchaseFollowOnCard } from '../Cards/PurchaseFollowOnCard';
import { PurchaseKetamineCard } from '../Cards/PurchaseKetamineCard';
import { DividerWithText } from '../Dividers';
import { RecommendedTherapyFlow } from '../Drawers';

enum Tabs {
   Prescriber,
   Psychotherapy,
   Psychiatry,
}

const AddMoreItem = () => {
   return (
      <Flex
         flexDir="column"
         bg="accent.sand"
         boxShadow="0px 5px 5px rgba(0, 0, 0, 0.15)"
         borderRadius="12px"
         overflow="hidden"
      >
         <Flex
            bg="linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%), #FFFFFF"
            p={{ base: 3, lg: 4 }}
            color="text.primary"
            alignItems="center"
         >
            <Badge
               fontWeight={600}
               textAlign="center"
               px={{ base: 2, lg: 4 }}
               borderRadius="12px"
               bg="accent.peach"
               color="accent.orangeDark"
               mr={2.5}
            >
               0 left
            </Badge>
            <Text>Add more to schedule your next appointment</Text>
         </Flex>
      </Flex>
   );
};

const AddonList = ({
   addons,
   isInsured,
}: {
   addons: InsuranceAvailableAddonForPurchase[];
   isInsured: boolean;
}) => {
   if (addons.length === 0) {
      return <AddMoreItem />;
   }
   return addons.map((addon) => {
      return (
         <AvailableAppointmentBankItem
            key={addon.encounterName}
            addon={addon}
            isInsurance={isInsured}
         />
      );
   });
};

const KetamineTreatmentPanel = () => {
   const {
      online: { location },
   } = useScheduling();

   const { programEncounters } = useProgramEncounters();

   const availableProgramAppointments = programEncounters.filter(
      (appointment) => {
         return appointment.usedCount < appointment.purchasedCount;
      },
   );

   const { canBuy: canBuyFollowOns } = useCanBuyFollowOns();

   return (
      <TabPanel py={0} display="flex" flexDir="column">
         <Accordion allowMultiple gap={4} display="flex" flexDir="column">
            {availableProgramAppointments
               .filter((appointment) => !appointment.hideFromBankTab)
               .map((appointment, index) => {
                  return (
                     <AppointmentBankItem
                        key={appointment.templateName + index}
                        appointment={{
                           name: appointment.title,
                           availableCount:
                              appointment.purchasedCount -
                              appointment.usedCount,
                           schedulingUrl: generateSchedulingLink(
                              appointment.templateName,
                              location,
                           ),
                           appointmentTemplate: appointment.templateName,
                        }}
                        isLocked={!appointment.schedulingBounds}
                        schedulingNote={appointment.schedulingHint}
                     />
                  );
               })}
         </Accordion>

         {availableProgramAppointments.length === 0 && canBuyFollowOns ? (
            <PurchaseFollowOnCard />
         ) : null}
      </TabPanel>
   );
};

const TherapyPanel = () => {
   const { data: sessionData } = useSession();
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

   const patientIsInsured = sessionData?.insurance?.isValid ?? false;

   const { therapyAddons } = useInsuranceEncounters(patientIsInsured);

   const filteredTherapyAddons = useMemo(() => {
      return therapyAddons;
   }, [therapyAddons]);

   return (
      <TabPanel py={0} display="flex" flexDir="column">
         <Accordion allowMultiple gap={4} display="flex" flexDir="column">
            <AddonList
               addons={filteredTherapyAddons}
               isInsured={patientIsInsured}
            />
         </Accordion>

         {!patientIsInsured && (
            <>
               <Button
                  variant="link"
                  color="background.primary"
                  fontSize="xs"
                  fontWeight={600}
                  mt={5}
                  textAlign="center"
                  onClick={() => {
                     setIsDrawerOpen(true);
                  }}
               >
                  Recommended Therapy Flow
               </Button>
               <RecommendedTherapyFlow
                  isOpen={isDrawerOpen}
                  onClose={() => {
                     setIsDrawerOpen(false);
                  }}
               />
            </>
         )}
      </TabPanel>
   );
};

const MedManagementPanel = () => {
   const { data: sessionData } = useSession();
   const patientIsInsured = sessionData?.insurance?.isValid ?? false;

   const { prescriberAddons } = useInsuranceEncounters(patientIsInsured);
   const filteredPrescriberAddons = useMemo(() => {
      return prescriberAddons;
   }, [prescriberAddons]);

   return (
      <TabPanel py={0} display="flex" flexDir="column">
         {patientIsInsured ? (
            <>
               <NonKetamineDisclaimer />
               <Divider
                  h="1px"
                  w="full"
                  bg="background.primary"
                  opacity={0.1}
                  my={6}
               />
            </>
         ) : null}
         <Accordion allowMultiple gap={4} display="flex" flexDir="column">
            <AddonList
               addons={filteredPrescriberAddons}
               isInsured={patientIsInsured}
            />
         </Accordion>
      </TabPanel>
   );
};

const KetamineSwitchPanel = () => {
   return (
      <TabPanel>
         <PurchaseKetamineCard />
      </TabPanel>
   );
};

export const AppointmentBank = () => {
   const [tab, setTab] = useState<Tabs>(Tabs.Prescriber);

   const handleTabChange = (newIndex: Tabs) => {
      setTab(newIndex);
   };

   const { data: sessionData } = useSession();
   const patientIsInsured = sessionData?.insurance?.isValid ?? false;

   const { isLoading } = useInsuranceEncounters(patientIsInsured);

   const { isCurrentlyInKetamineProgram } = usePatientProgram();

   return (
      <Box id="available-appointments">
         <DividerWithText text="Book an appointment" mb={4} />
         <Flex
            flexDir="column"
            bg="card.tertiary"
            px={{ base: 3, lg: 6 }}
            py={{ base: 4, lg: 6 }}
            borderRadius="12px"
            pos="relative"
            mb={6}
         >
            <ChakraTabs
               variant="button"
               colorScheme="gray"
               index={tab}
               onChange={handleTabChange}
               isLazy
               maxW="calc(100vw - 3.56rem)"
            >
               <Flex
                  pos="relative"
                  flexDir={{ base: 'column', lg: 'row' }}
                  gap={4}
                  justifyContent="space-between"
                  alignItems="flex-start"
               >
                  <TabList
                     mt={{ lg: 0.5 }}
                     mb={{ base: 2, lg: 3 }}
                     p={0.5}
                     sx={{
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                        '::-webkit-scrollbar': {
                           display: 'none',
                        },
                     }}
                  >
                     {isCurrentlyInKetamineProgram ? (
                        <>
                           <Tab>Ketamine Treatment</Tab>
                           <Tab>Therapy</Tab>
                           <Tab>Psychiatry</Tab>
                        </>
                     ) : (
                        <>
                           <Tab>Therapy</Tab>
                           <Tab>Psychiatry</Tab>
                           <Tab>Ketamine Treatment</Tab>
                        </>
                     )}
                  </TabList>
               </Flex>

               <Divider
                  borderColor="background.primary"
                  opacity={0.1}
                  mb={6}
                  mt={3}
               />

               <SkeletonWithLoader
                  loadingText="Loading available appointments..."
                  minH="100px"
                  borderRadius={12}
                  isLoaded={!isLoading}
               >
                  {isCurrentlyInKetamineProgram ? (
                     <TabPanels>
                        <KetamineTreatmentPanel />
                        <TherapyPanel />
                        <MedManagementPanel />
                     </TabPanels>
                  ) : (
                     <TabPanels>
                        <TherapyPanel />
                        <MedManagementPanel />
                        <KetamineSwitchPanel />
                     </TabPanels>
                  )}
               </SkeletonWithLoader>
            </ChakraTabs>
         </Flex>
      </Box>
   );
};
