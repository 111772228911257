import { Box, BoxProps } from '@chakra-ui/react';

import { Card } from '@/components/Cards';

type Props = BoxProps;

const UploadIdStep = (props: Props) => {
   return (
      <Box {...props}>
         <Card>
            <Card.Title>{`Let's get you verified`}</Card.Title>
            <Card.Text>
               {`We'd like to confirm your identity. Please upload an image of
               your driver's license or passport.`}
            </Card.Text>
            <Card.ButtonLink mt={4} href="/user-consent/document-upload">
               Upload
            </Card.ButtonLink>
            <Card.Image
               src="/images/medical-consult-woman.svg"
               alt="Card image"
            />
         </Card>
      </Box>
   );
};

export default UploadIdStep;
