import type { ButtonProps as ChakraButtonProps } from '@chakra-ui/react';
import { Button as ChakraButton } from '@chakra-ui/react';

export const Button = (buttonLinkProps: ChakraButtonProps) => {
   return (
      <ChakraButton
         colorScheme="buttonPrimary"
         size={{ base: 'xs', lg: 'sm' }}
         position="relative"
         zIndex={1}
         isDisabled={buttonLinkProps.isLoading || buttonLinkProps.isDisabled}
         {...buttonLinkProps}
      />
   );
};
