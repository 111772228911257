const LOCALSTORAGE_KEY = 'skipped-program-goals';

const skipProgramGoalsLocalStorage = {
   markAsSkipped: () => {
      window.localStorage.setItem(LOCALSTORAGE_KEY, '1');
   },

   getIsSkipped: () => {
      return window.localStorage.getItem(LOCALSTORAGE_KEY);
   },
};

export default skipProgramGoalsLocalStorage;
