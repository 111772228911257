'use client';
import { Flex, Heading, Text } from '@chakra-ui/react';
import { Box, Skeleton } from '@chakra-ui/react';
import { WelkinPrograms } from '@innerwell/dtos';
import { useRouter, useSearchParams } from 'next/navigation';

import { ExtendedLayout } from '@/components/Home/ExtendedLayout';
import { IntakeLayout } from '@/components/Home/IntakeLayout';
import { InnerwellLogo } from '@/components/Images';
import { WelcomeModal } from '@/components/Modals/WelcomeModal';

import { usePatientProgram } from '@/contexts/patient-program-context';
import { useSession } from '@/contexts/session-context';

export const HomepageClientPage = () => {
   const { data: sessionData } = useSession();
   const { replace } = useRouter();
   const {
      programPhase: { phase, program },
   } = usePatientProgram();

   const searchParams = useSearchParams();
   const showWelcomeModal = searchParams.get('show_welcome_modal') === '1';

   const handleWelcomeModalClose = () => {
      replace('/');
   };

   return (
      <Flex
         maxW={{ lg: 'container.md' }}
         flexDir="column"
         mx="auto"
         pt={{ base: 'none', lg: '3.75rem' }}
      >
         {showWelcomeModal && (
            <WelcomeModal onCloseComplete={handleWelcomeModalClose} />
         )}

         <InnerwellLogo mb={9} mt={7} fill="white" display={{ lg: 'none' }} />
         <Text>{`Hi, ${sessionData?.given_name}`}</Text>
         <Heading>Next Steps</Heading>

         <Box pt={5} pb={{ base: 12, lg: 8 }}>
            <Skeleton minH="450px" isLoaded={!!phase} borderRadius="12px">
               {program === WelkinPrograms.Intake ? (
                  <IntakeLayout />
               ) : (
                  <ExtendedLayout />
               )}
            </Skeleton>
         </Box>
      </Flex>
   );
};
