import { useQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

export const useInsuranceEncounters = (forInsurance: boolean) => {
   const { data, ...rest } = useQuery({
      queryKey: queryKeys.insuranceEncounters(forInsurance),
      queryFn: async () => {
         const response = await webApiClient.insurance.getAvailableEncounters({
            query: {
               forInsurance,
            },
         });
         return response.body;
      },
      refetchInterval: 30000,
      refetchOnWindowFocus: true,
   });

   return {
      ...rest,
      therapyCategoryId: data?.therapyCategoryId ?? null,
      prescriberCategoryId: data?.prescriberCategoryId ?? null,
      therapyAddons: data?.therapyAddons ?? [],
      prescriberAddons: data?.prescriberAddons ?? [],
   };
};
