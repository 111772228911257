import { BoxProps, Skeleton, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { useSearchParams } from 'next/navigation';
import React from 'react';

import { useMyProgramGoals } from '@/hooks/react-query/useMyProgramGoals';
import useThemedToast from '@/hooks/useThemedToast';

import { useSession } from '@/contexts/session-context';
import skipProgramGoalsLocalStorage from '@/utils/skipProgramGoalsLocalStorage';

import { Card } from '../Cards';

import { queryKeys } from '@/types/query-keys';

type Props = {
   onCompleted?: () => void;
   omitTheCheckForSkipped?: boolean;
} & Omit<BoxProps, 'backgroundImage'>;

const HomeSetProgramGoalsStep = ({
   onCompleted,
   omitTheCheckForSkipped = false,
   ...props
}: Props) => {
   const queryClient = useQueryClient();

   const { data: sessionData } = useSession();
   const { replace } = useRouter();
   const searchParams = useSearchParams();
   const { toastSuccess } = useThemedToast();

   const { programGoals, status: programGoalsStatus } = useMyProgramGoals();

   const [isProgramGoalsFinished, setIsProgramGoalsFinished] =
      React.useState(false);

   React.useEffect(() => {
      if (programGoals?.at(0) && !isProgramGoalsFinished) {
         setIsProgramGoalsFinished(true);
      }
   }, [isProgramGoalsFinished, programGoals]);

   const programGoalsFinishedQuery = searchParams.get('program_goals_finished');

   React.useEffect(() => {
      if (sessionData) {
         if (programGoalsFinishedQuery === 'true') {
            if (onCompleted) {
               onCompleted();
            }
            queryClient.invalidateQueries({
               queryKey: queryKeys.myProgramGoals,
            });
            toastSuccess(
               `Thanks, ${sessionData?.given_name}! You can return to your goals anytime in the My Journey tab.`,
            );
            setIsProgramGoalsFinished(true);

            const nextSearchParams = new URLSearchParams(searchParams);
            nextSearchParams.delete('program_goals_finished');

            replace(`/?${nextSearchParams.toString()}`);
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [sessionData]);

   const isLoading = programGoalsStatus === 'pending';

   // Skip program goals
   const [isProgramGoalsSkipped, setIsProgramGoalsSkipped] =
      React.useState(false);

   React.useEffect(() => {
      const skipProgramGoals = skipProgramGoalsLocalStorage.getIsSkipped();

      if (skipProgramGoals) {
         setIsProgramGoalsSkipped(true);
      }
   }, []);

   const handleSkipProgramGoals = () => {
      skipProgramGoalsLocalStorage.markAsSkipped();

      if (onCompleted) {
         onCompleted();
      }

      toastSuccess('Program goals skipped.');

      setIsProgramGoalsSkipped(true);
   };

   return isProgramGoalsSkipped === true && !omitTheCheckForSkipped ? null : (
      <Skeleton
         isLoaded={!isLoading}
         borderRadius="12px"
         minH={isLoading || !isProgramGoalsFinished ? '243px' : 0}
      >
         {!isProgramGoalsFinished || omitTheCheckForSkipped ? (
            <Card mb={isLoading || !isProgramGoalsFinished ? 8 : 0} {...props}>
               <Card.Image
                  src="/images/program-goals-card.png"
                  alt="Card image"
               />
               <Card.Title>
                  <Text as="span">Take a moment to reflect on your</Text>{' '}
                  <Text as="span" fontStyle="italic">
                     Why?
                  </Text>
               </Card.Title>
               <Card.Text>
                  You’ve just taken a big step toward healing by meeting with
                  your psychiatric clinician. Now it’s time to reflect on your
                  “why” for treatment. Being clear about your hopes and aims can
                  be a helpful foundation for the journey ahead.
               </Card.Text>
               <Card.ButtonGroup>
                  <Card.ButtonLink href="/set-program-goals">
                     Set Goals for Treatment
                  </Card.ButtonLink>
                  <Card.Button variant="link" onClick={handleSkipProgramGoals}>
                     Skip for now
                  </Card.Button>
               </Card.ButtonGroup>
            </Card>
         ) : null}
      </Skeleton>
   );
};

export default HomeSetProgramGoalsStep;
