import {
   Box,
   Drawer,
   DrawerBody,
   DrawerCloseButton,
   DrawerContent,
   DrawerOverlay,
   Flex,
   Heading,
   ListItem,
   OrderedList,
   Text,
} from '@chakra-ui/react';
import { NextImage } from '@innerwell/chakra/images';

import { Illustration } from '@/components/Illustration';

type Props = {
   isOpen: boolean;
   onClose: () => void;
};

export const RecommendedTherapyFlow = ({ isOpen, onClose }: Props) => {
   return (
      <Drawer size="md" isOpen={isOpen} placement="right" onClose={onClose}>
         <DrawerOverlay />
         <DrawerContent maxW="sideDrawer" pos="relative">
            <DrawerCloseButton
               mt={5}
               size="lg"
               borderRadius="86px"
               backgroundColor={'neutral.200'}
               pos="absolute"
               right={{ base: 6, lg: 8 }}
               top={{ base: 2, lg: 4 }}
            />

            <DrawerBody
               pt={0}
               pb={6}
               px={{ base: 5, lg: 10 }}
               mt={20}
               display="flex"
               flexDir="column"
            >
               <Heading size="small" pr={{ lg: 2 }} mb={1}>
                  Recommended Therapy Flow
               </Heading>
               <Flex flexDir="column" gap={4}>
                  <Flex mt={6} gap={4} alignItems="center">
                     <NextImage
                        w={{
                           base: '60px',
                           lg: '80px',
                        }}
                        h={{
                           base: '60px',
                           lg: '80px',
                        }}
                        src="/images/ben-medrano-peach.jpg"
                        alt="Ben Medrano"
                        borderRadius="50%"
                     />
                     <Box textAlign="left" color="text.secondary">
                        <Text fontWeight={500}>Dr. Ben Medrano</Text>
                        <Text>Chief Medical Officer</Text>
                     </Box>
                  </Flex>

                  <OrderedList
                     mt={4}
                     gap={{
                        base: 4,
                        lg: 6,
                     }}
                     display="flex"
                     flexDir="column"
                     fontSize={{
                        base: 'md',
                        lg: 'lg',
                     }}
                  >
                     <ListItem>
                        Schedule your Meet Your Therapist appointment after
                        completing your Med Consult appointment. This gives your
                        therapist a chance to get to know you and discuss how
                        utilizing therapy throughout your journey will enable
                        you to maximize the benefits of ketamine.
                     </ListItem>
                     <ListItem>
                        Meet your therapist again for your Preparation Session
                        ahead of your first Psychotherapist-Led Ketamine
                        Session. During this appointment, you and your therapist
                        will review your intentions for your 1st ketamine
                        experience, and discuss how to prepare a comfortable
                        setting for your experience including music selection
                        and a guided meditation.
                     </ListItem>
                     <ListItem>
                        Complete your Psychotherapist-Led Ketamine Session. Your
                        therapist will be with you on Zoom to guide and support
                        you during your entire experience. They’ll check in with
                        you if they notice you’re having a particularly strong
                        emotional experience, in addition to taking notes of
                        things that you talk about during your journey so you’ll
                        be able to reflect on them afterwards.
                     </ListItem>
                     <ListItem>
                        Schedule your Integration Sessions on a weekly or
                        bi-weekly basis. These sessions are a chance for you to
                        reflect on your ketamine experiences with the support of
                        your therapist. For maximum benefit, you should take
                        your prescribed ketamine 24-72 hours before your
                        Integration Session.
                     </ListItem>
                  </OrderedList>
               </Flex>
               <Flex
                  pt={6}
                  mt="auto"
                  alignItems="center"
                  justifyContent="center"
                  w="full"
                  mb={{ base: 2, lg: 10 }}
               >
                  <Illustration
                     src="/illustrations/recommended-therapy.svg"
                     alt=""
                     w={{
                        base: '197px',
                        lg: '300px',
                     }}
                     h={{
                        base: '118px',
                        lg: '190px',
                     }}
                  />
               </Flex>
            </DrawerBody>
         </DrawerContent>
      </Drawer>
   );
};
