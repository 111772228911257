import { CanBuyFollowOnsResponseDto } from '@innerwell/dtos';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

export default function useCanBuyFollowOns(
   opts: Omit<
      UseQueryOptions<unknown, unknown, CanBuyFollowOnsResponseDto>,
      'queryKey' | 'queryFn' | 'initialData'
   > = {},
) {
   const { ...queryOpts } = opts;
   const { data: response, ...rest } = useQuery({
      queryKey: queryKeys.followOnProducts,
      queryFn: async () => {
         const result = await webApiClient.followons.canBuyFollowOns({});
         return result.body;
      },
      placeholderData: (prev) => prev,
      ...queryOpts,
   });

   return {
      ...rest,
      canBuy: response?.canBuy ?? null,
      cannotBuyReason: response?.message ?? null,
   };
}
