import { MoodTrackingEnum } from '@innerwell/dtos';

import { IconName } from '@/components/Icon';

export type MoodIconName = Extract<
   IconName,
   | 'mood-extremely-good'
   | 'mood-very-good'
   | 'mood-good'
   | 'mood-not-good-nor-bad'
   | 'mood-bad'
   | 'mood-very-bad'
   | 'mood-extremely-bad'
>;

export interface IMoodInfo {
   value: MoodTrackingEnum;
   text: string;
   iconName: MoodIconName;
   backgroundColor: string;
   backgroundHoverColor: string;
   mainColor: string;
}

export const WEEKLY_MOOD_OPTIONS: IMoodInfo[] = [
   {
      value: MoodTrackingEnum.ExtremelyGood,
      text: 'Extremely good',
      iconName: 'mood-extremely-good',
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      backgroundHoverColor: '#fff',
      mainColor: '#417777',
   },
   {
      value: MoodTrackingEnum.VeryGood,
      text: 'Very good',
      iconName: 'mood-very-good',
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      backgroundHoverColor: '#fff',
      mainColor: '#499C9C',
   },
   {
      value: MoodTrackingEnum.Good,
      text: 'Good',
      iconName: 'mood-good',
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      backgroundHoverColor: '#fff',
      mainColor: '#66C2C2',
   },
   {
      value: MoodTrackingEnum.NeitherBad,
      text: 'Neither bad, nor good',
      iconName: 'mood-not-good-nor-bad',
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      backgroundHoverColor: '#fff',
      mainColor: '#A5A9A9;',
   },
   {
      value: MoodTrackingEnum.Bad,
      text: 'Bad',
      iconName: 'mood-bad',
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      backgroundHoverColor: '#fff',
      mainColor: '#DF7777',
   },
   {
      value: MoodTrackingEnum.VeryBad,
      text: 'Very bad',
      iconName: 'mood-very-bad',
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      backgroundHoverColor: '#fff',
      mainColor: '#B74B4B',
   },
   {
      value: MoodTrackingEnum.ExtremelyBad,
      text: 'Extremely bad',
      iconName: 'mood-extremely-bad',
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      backgroundHoverColor: '#fff',
      mainColor: '#881C1C',
   },
];
