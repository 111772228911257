import { Box, BoxProps, Button, Text, Tooltip } from '@chakra-ui/react';

export const NonKetamineDisclaimer = ({ ...boxProps }: BoxProps) => {
   return (
      <Box mx="auto" {...boxProps}>
         <Box borderRadius={16} overflow="hidden">
            <Box
               bg="background.primary"
               transition="all .15s ease"
               px={{ base: 6, lg: 8 }}
               py={{ base: 2, lg: 4 }}
               pr={{ base: 10, lg: 14 }}
            >
               <Text fontWeight={600}>
                  Non-ketamine Psychiatry and Medication Management Services
               </Text>
            </Box>
            <Box bg="white" py={4} px={{ base: 4, lg: 6 }}>
               <Text color="text.primary">
                  {`Ketamine can be powerful, but everyone's brain chemistry is
         unique and sometimes other medications can be helpful.
         That's why Innerwell takes a comprehensive approach to
         mental health with a`}{' '}
                  <Tooltip
                     textColor="white"
                     label={`Common medications include
                           SSRIs, SNRIs, NDRIs, mood stabilizers,
                           benzodiazepines, atypical antipsychotics.`}
                     hasArrow
                     shouldWrapChildren
                     px={3}
                     py={2}
                     borderRadius={8}
                  >
                     <Button
                        as="span"
                        variant="link"
                        color="background.primary"
                        fontSize="body"
                     >
                        range of psychiatric medications
                     </Button>
                  </Tooltip>{' '}
                  tailored to to suit your unique needs for diverse{' '}
                  <Tooltip
                     textColor="white"
                     label={`Common conditions include
                        anxiety, depression, OCD, Insomnia, PTSD,
                        ADHD and others.`}
                     hasArrow
                     shouldWrapChildren
                     borderRadius={8}
                     px={3}
                     py={2}
                  >
                     <Button
                        as="span"
                        variant="link"
                        color="background.primary"
                        fontSize="body"
                     >
                        mental health conditions
                     </Button>
                  </Tooltip>
                  .
               </Text>
            </Box>
         </Box>
      </Box>
   );
};
