import { NextImage } from '@innerwell/chakra/images';
import type { NextImageProps } from '@innerwell/chakra/images/NextImage';
type ImageProps = {
   imagePosition?: 'center' | 'bottom';
} & NextImageProps;

export const Image = ({
   imagePosition = 'bottom',
   ...imageProps
}: ImageProps) => {
   return (
      <NextImage
         display={{ base: 'none', lg: 'block' }}
         imgWidth={256}
         imgHeight={251}
         pos="absolute"
         objectFit="cover"
         zIndex={0}
         w={imagePosition === 'bottom' ? '180px' : '175px'}
         h={imagePosition === 'bottom' ? '180px' : '175px'}
         transform={imagePosition === 'bottom' ? 'none' : 'translateY(50%)'}
         bottom={imagePosition === 'bottom' ? 0 : '50%'}
         right={imagePosition === 'bottom' ? 0 : 16}
         objectPosition="25% 0%"
         borderRadius={imagePosition === 'bottom' ? 0 : '12px'}
         maxH="100%"
         {...imageProps}
      />
   );
};
