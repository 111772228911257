import { IconProps } from '@chakra-ui/react';
import { SharedProgramPhases } from '@innerwell/dtos';

import { Card } from '@/components/Cards';
import {
   MedicalReviewInProgressGraphic,
   MissedAppointmentGraphic,
   PlanPausedGraphic,
   SorryToSeeYouGoGraphic,
   TroubleWithPaymentGraphic,
} from '@/components/Images';

export type LockedExplanation = {
   heading: string | JSX.Element;
   text: string | JSX.Element;
   icon?: (props: IconProps) => JSX.Element;
   cta?: React.ReactNode;
};

export const LOCKED_PHASES = new Map<SharedProgramPhases, LockedExplanation>([
   [
      SharedProgramPhases.MedicalClearanceWaiting,
      {
         heading: 'Awaiting Medical Clearance',
         text: `Your Psychiatric Clinician requires additional information to
         better understand your medical and psychiatric history before
         proceeding with treatment.
         You will receive an email titled "Medical Information Request,
         Required for Innerwell Treatment" that outlines necessary next steps.
         Follow the instructions provided in that email to obtain and submit
         the requested documentation to Innerwell promptly. Please note that
         future prescription(s) cannot be issued until this documentation is
         received and reviewed.`,
         icon: MedicalReviewInProgressGraphic,
      },
   ],

   [
      SharedProgramPhases.PatientDiscontinuedPrematurely,
      {
         heading: 'We’re sorry to see you go',
         text: `Contact Member Support if
               you’d like to reinstate your care.`,
         icon: SorryToSeeYouGoGraphic,
         cta: (
            <Card.ButtonLink href="/support">
               Contact Member Support
            </Card.ButtonLink>
         ),
      },
   ],
   [
      SharedProgramPhases.UnderReview,
      {
         heading: 'Medical Review in Progress',
         text: `We’re currently reviewing your case notes to confirm treatment
               eligibility. This may take a few days, especially if we’ve
               requested your permission to contact an external provider. Once
               confirmed, we’ll be in touch when your prescription is on the
               way.`,
         icon: MedicalReviewInProgressGraphic,
         cta: (
            <Card.ButtonLink href="/support">
               Contact Member Support
            </Card.ButtonLink>
         ),
      },
   ],
   [
      SharedProgramPhases.PaymentFailed,
      {
         heading: 'We’re having some trouble collecting your latest payment.',
         text: `Please reach out to Member Support to resolve
               your outstanding balance and update your card information. We
               must cancel the any upcoming appointments until your outstanding
               balance is resolved.`,
         icon: TroubleWithPaymentGraphic,
         cta: (
            <Card.ButtonLink href="/support">
               Contact Member Support
            </Card.ButtonLink>
         ),
      },
   ],
   [
      SharedProgramPhases.PlanPaused,
      {
         heading: 'We’ll be here when you’re ready.',
         text: `Contact Member Support if
               you’d like to reinstate your care.`,
         icon: PlanPausedGraphic,
         cta: (
            <Card.ButtonLink href="/support">
               Contact Member Support
            </Card.ButtonLink>
         ),
      },
   ],
   [
      SharedProgramPhases.PatientMissedAppointment,
      {
         heading: 'We’re sorry we missed you.',
         text: (
            <>
               Reminder: there is a $100 fee for missing your appointment, or
               canceling/rescheduling within 48 hours of its start time. This
               fee is charged out of respect for our clinicians’ time. <br />
               <br />
               Our Member Support team will notify you about rescheduling once
               payment has been made.
            </>
         ),
         icon: MissedAppointmentGraphic,

         cta: (
            <Card.ButtonLink href="/purchase/missed-appointment">
               Make Payment
            </Card.ButtonLink>
         ),
      },
   ],
]);
