import {
   AccordionButton,
   AccordionItem,
   AccordionItemProps,
   AccordionPanel,
   Box,
} from '@chakra-ui/react';

type Props = {
   expandedComponent?: JSX.Element;
   initialComponent?: JSX.Element;
} & AccordionItemProps;

export const StepAccordionWrapper = ({
   initialComponent: InitialComponent,
   expandedComponent: ExpandedComponent,
   ...props
}: Props) => {
   return (
      <AccordionItem border={0} mb={4} mt={-2} mx={-2} {...props}>
         {({ isExpanded }) => (
            <>
               <AccordionButton display="none" />

               {!isExpanded && InitialComponent && (
                  <Box p={2}>{InitialComponent}</Box>
               )}

               <AccordionPanel p={2} _empty={{ display: 'none' }}>
                  {isExpanded && ExpandedComponent}
               </AccordionPanel>
            </>
         )}
      </AccordionItem>
   );
};
