import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';
import { StepDoneIcon } from '@innerwell/chakra/icons';
import React from 'react';

import { Icon } from '../Icon';

interface IProps extends FlexProps {
   title: string;
   fill: string;
   variant?: 'dark' | 'light';
   status?: 'upcoming' | 'completed';
}

const HomeStepUpcomingProgress: React.FC<IProps> = ({
   title,
   fill,
   variant = 'dark',
   status = 'upcoming',
   ...props
}: IProps) => {
   const isDark = variant === 'dark';
   return (
      <Flex
         position="relative"
         justifyContent="flex-start"
         alignItems="center"
         bg={
            isDark
               ? 'background.fourth'
               : 'linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%), #FFFFFF'
         }
         borderRadius={12}
         w="100%"
         minH="56px"
         opacity={isDark ? 0.6 : 0.9}
         {...props}
      >
         <Flex
            flexDir="column"
            position="absolute"
            top="-2.05rem"
            left={5}
            alignItems="center"
            justifyContent="center"
         >
            <Icon
               name="vertical-dashed-line"
               h={14}
               w="1px"
               color={fill}
               visibility={status === 'upcoming' ? 'visible' : 'hidden'}
            />

            <Box
               boxSize=".7rem"
               bg={fill}
               rounded="full"
               opacity={1}
               flexShrink={0}
               flexGrow={0}
            />
         </Flex>

         <Text
            color={isDark ? 'white' : 'text.primary'}
            fontSize="md"
            pl="43px"
         >
            {title}
         </Text>
         {status === 'completed' && (
            <StepDoneIcon
               ml="auto"
               fill="accent.green"
               stroke="buttonSecondary.700"
               mr={4}
            />
         )}
      </Flex>
   );
};

export default HomeStepUpcomingProgress;
