import { Flex, Text, useBreakpoint } from '@chakra-ui/react';
import { AppointmentItem } from '@innerwell/dtos';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { SwiperSlide } from 'swiper/react';

import { useAppointmentLoadingStatus } from '@/hooks/useAppointmentLoadingStatus';

import { useSession } from '@/contexts/session-context';
import { IAppointmentStepInfo } from '@/utils/appointment-steps.const';

import { CardSwiper } from './CardSwiper';
import { AppointmentWithRescheduleCard } from '../Cards/AppointmentWithRescheduleCard';
import { InfoDialog } from '../Dialog/InfoDialog';
import { UpcomingAppointmentGraphic } from '../Images';

type AppointmentWithInfo = AppointmentItem &
   Pick<IAppointmentStepInfo, 'title'>;

type Props = {
   appointments: AppointmentWithInfo[] | null;
};

export const UpcomingAppointments = ({ appointments }: Props) => {
   const breakpoint = useBreakpoint();
   const isMobile = ['base', 'sm', 'md'].includes(breakpoint);

   const featuredAppointment = useMemo(() => {
      // On mobile we do not show today's appointment as a featured one
      if (!appointments || isMobile) return null;

      return appointments.find((appointment) =>
         DateTime.now().hasSame(
            DateTime.fromISO(appointment.startDateTime),
            'day',
         ),
      );
   }, [isMobile, appointments]);

   const otherAppointments = useMemo(() => {
      if (!appointments) return null;

      return appointments.filter(
         (appointment) => appointment.id !== featuredAppointment?.id,
      );
   }, [appointments, featuredAppointment]);

   const { data: sessionData } = useSession();

   const appointmentLoadingStatus = useAppointmentLoadingStatus();

   return (
      <Flex flexDir="column" gap={8}>
         {featuredAppointment ? (
            <AppointmentWithRescheduleCard
               key={featuredAppointment.id}
               appointment={featuredAppointment}
               title={featuredAppointment.title}
            />
         ) : null}

         {otherAppointments && otherAppointments.length > 0 ? (
            <>
               {otherAppointments.length === 1 ? (
                  otherAppointments.map((appointment) => {
                     return (
                        <AppointmentWithRescheduleCard
                           key={appointment.id}
                           appointment={appointment}
                           title={appointment.title}
                        />
                     );
                  })
               ) : (
                  <CardSwiper mb={{ base: 4, lg: 0 }}>
                     {otherAppointments.map((appointment) => {
                        return (
                           <SwiperSlide key={appointment.id}>
                              <AppointmentWithRescheduleCard
                                 key={appointment.id}
                                 appointment={appointment}
                                 title={appointment.title}
                                 size="small"
                              />
                           </SwiperSlide>
                        );
                     })}
                  </CardSwiper>
               )}
               <InfoDialog py={4} px={4}>
                  <Text fontSize="md">
                     {`Reminder: there is a $100 fee for missing your appointment,
                           or for canceling/rescheduling your appointment within 48 hours
                           of its start time. This fee is charged out of respect for our
                           clinicians’ time.`}
                  </Text>
               </InfoDialog>
            </>
         ) : null}

         {otherAppointments?.length === 0 &&
         !featuredAppointment &&
         !appointmentLoadingStatus ? (
            <Flex
               bg="background.secondary"
               alignItems="center"
               justifyContent="center"
               borderRadius="12px"
               py={6}
               px={{ base: 5, lg: 8 }}
               flexDir="column"
               color="text.primary"
               textAlign="center"
            >
               <UpcomingAppointmentGraphic mb={2} />
               <Text mb={1.5} size="paragraphLarge" fontWeight={600}>
                  No appointments at the moment, {sessionData?.given_name}
               </Text>
               <Text>
                  Enjoy the rest of your day and keep up the fantastic work!
               </Text>
            </Flex>
         ) : null}
      </Flex>
   );
};
