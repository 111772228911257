import { useQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

export const useMissedAppointments = () => {
   return useQuery({
      queryKey: queryKeys.missedAppointments,
      queryFn: async () => {
         const response =
            await webApiClient.missedAppointments.getMissedAppointments();

         return response.body;
      },
      refetchOnWindowFocus: true,
      refetchInterval: 1000 * 60 * 1,
   });
};
