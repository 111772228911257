import { MissedAppointmentsResponseDto } from '@innerwell/dtos';

import { MissedAppointmentGraphic } from '@/components/Images';

import { Card } from '../components';

export const PatientMissedAppointmentCard = ({
   missedAppointment,
}: {
   missedAppointment: MissedAppointmentsResponseDto;
}) => {
   // const queryClient = useQueryClient();
   // const { toastSuccess, toastError } = useThemedToast();
   // const { mutate } = useMutation({
   //    mutationFn() {
   //       return webApiClient.missedAppointments.payment({
   //          body: {
   //             missedAppointmentCdtId: missedAppointment.id,
   //          },
   //       });
   //    },
   //    onSuccess() {
   //       toastSuccess('Payment successful');
   //       queryClient.invalidateQueries({
   //          queryKey: queryKeys.missedAppointments,
   //       });
   //    },
   //    onError(err) {
   //       toastError('Payment error', getErrorMessage(err));
   //    },
   // });

   return (
      <Card minH="none" bg="background.secondary">
         <Card.Title
            fontWeight={600}
            display="flex"
            gap={3}
            alignItems="center"
         >
            {/* {Icon ? (
               <Icon
                  w={{ base: '60px', lg: '80px' }}
                  h={{ base: '60px', lg: '80px' }}
               />
            ) : null} */}
            <MissedAppointmentGraphic
               w={{ base: '60px', lg: '80px' }}
               h={{ base: '60px', lg: '80px' }}
            />
            We’re sorry we missed you.
         </Card.Title>
         <Card.Text fontSize="lg" pr={4}>
            Reminder: there is a $100 fee for missing your appointment, or
            canceling/rescheduling within 48 hours of its start time. This fee
            is charged out of respect for our clinicians’ time. <br />
            <br />
            Our Member Support team will notify you about rescheduling once
            payment has been made.
         </Card.Text>
         <Card.ButtonGroup>
            <Card.ButtonLink
               href={`/missed-appointment/${missedAppointment.id}`}
            >
               Make Payment
            </Card.ButtonLink>
         </Card.ButtonGroup>
      </Card>
   );
};
