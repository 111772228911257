import { useDisclosure } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { useState } from 'react';

import { useDailyMoodTracking } from '@/hooks/react-query/useDailyMoodTracking';

import { Card } from '@/components/Cards';
import DailyMoodTrackingModal from '@/components/Modals/DailyMoodTrackingModal';

type Props = {
   onCompleted: () => void;
};

const HomeTrackFirstDailyMood = ({ onCompleted }: Props) => {
   const initialMoodTrackingModal = useDisclosure();
   const [startDate, setStartDate] = useState('');

   const { refetch: refetchMoodTracking } = useDailyMoodTracking();

   const handleOpenMoodTrackingModals = () => {
      setStartDate(DateTime.now().toISODate());
      initialMoodTrackingModal.onOpen();
   };

   const handleFinishedDailyMoodTracking = () => {
      refetchMoodTracking();
      onCompleted();
   };

   return (
      <>
         <Card backgroundImage="/images/daily-mood-bg.jpg">
            <Card.Title>Daily Mood Tracking</Card.Title>
            <Card.Text>
               One of the first steps towards learning about what’s going on
               inside of your mind is to begin cultivating awareness. This 2
               minute check-in can help you connect with how you’re feeling each
               day, while also communicating your emotional progress with your
               care team.
            </Card.Text>
            <Card.ButtonGroup>
               <Card.Button onClick={handleOpenMoodTrackingModals}>
                  Track my mood
               </Card.Button>
               <Card.Button variant="link" onClick={onCompleted}>
                  Skip for now
               </Card.Button>
            </Card.ButtonGroup>
         </Card>
         <DailyMoodTrackingModal
            date={startDate}
            initialMoodTrackingModal={initialMoodTrackingModal}
            onFinishedDailyMoodTracking={handleFinishedDailyMoodTracking}
         />
      </>
   );
};

export default HomeTrackFirstDailyMood;
