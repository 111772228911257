import { Button, Divider, Flex, Input } from '@chakra-ui/react';
import { ClaimMdPatientRelationshipCode } from '@innerwell/api-claim-md-types';
import {
   EligibilityResponseDataDto,
   getEligibilitySchema,
} from '@innerwell/dtos';
import { ChakraStylesConfig, Select, SingleValue } from 'chakra-react-select';
import { DateTime } from 'luxon';
import { Controller, DeepPartial } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { z } from 'zod';

import {
   GetEligibilityParams,
   useGetInsuranceEligibility,
} from '@/hooks/react-query/useGetInsuranceEligibility';
import useInsurances from '@/hooks/react-query/useInsurances';
import { useZodForm } from '@/hooks/useZodForm';

import CustomCheckbox from '../Devkit/CustomFormControl/CustomCheckbox/CustomCheckbox';
import CustomFormControl from '../Devkit/CustomFormControl/CustomFormControl';

type Option = SingleValue<{
   label: string;
   value: string;
}>;

const chakraStyles: ChakraStylesConfig<Option> = {
   option: (provided, { isSelected }) => ({
      ...provided,
      fontSize: '1rem',
      color: isSelected ? 'white' : 'black',
      bgColor: isSelected ? 'background.primary' : 'white',
      _hover: {
         color: 'white',
         bgColor: 'background.primary',
      },
   }),
   menuList: (provided) => ({
      ...provided,
      mb: 3,
      maxHeight: '300px',
   }),

   control: (provided) => ({
      ...provided,
      bg: 'white',
   }),
};

const insuredSchema = getEligibilitySchema.shape.insured.omit({ dob: true });

// Change the insured.dob to string in this schema, but keep all fields from getEligibilitySchema
const editedEligibilitySchema = getEligibilitySchema
   .omit({
      formsortUUID: true,
   })
   .extend({
      insured: insuredSchema.extend({
         dob: z
            .string()
            .min(6, 'Date is required.')
            .regex(
               /(0[1-9]|1[012])(\/|-)(0[1-9]|[12][0-9]|3[01])(\/|-)(1|2)\d{3}/,
               'Invalid date.',
            )
            .transform((value) => {
               const cleanValue = value.replaceAll('_', '');
               try {
                  return DateTime.fromFormat(cleanValue, 'MM/dd/yyyy', {
                     zone: 'utc',
                  })
                     .toUTC()
                     .toISO({
                        suppressMilliseconds: true,
                     });
               } catch (e) {
                  return '';
               }
            }),
      }),
   });

export type EligibilityFormData = z.infer<typeof editedEligibilitySchema>;

export const EligibilityForm = ({
   onBeforeSubmit,
   onSubmit,
   defaultValues,
   onResult,
   onError,
   insuranceNotCoveredButton,
   footerRow: FooterRow,
   isDisabled,
}: {
   defaultValues?: DeepPartial<EligibilityFormData>;
   onBeforeSubmit?: (
      data: GetEligibilityParams,
   ) => GetEligibilityParams | undefined;
   // Allows override of the default eligibility check method.
   // Use case for this is to have the eligibility check in intake which does the check
   // plus some additional things on the backend, so we must call a different method.
   // onResult & onError won't be called in that case
   onSubmit?: (data: GetEligibilityParams) => void;
   onResult?: (
      res: EligibilityResponseDataDto,
      params: GetEligibilityParams,
   ) => void;
   onError?: (error: unknown) => void;
   insuranceNotCoveredButton?: {
      onClick: () => void;
   };
   footerRow?: React.FC<{
      isLoading: boolean;
   }>;
   isDisabled?: boolean;
}) => {
   const form = useZodForm({
      schema: editedEligibilitySchema,
      defaultValues: {
         relationshipCode: ClaimMdPatientRelationshipCode.Self,
         serviceDate: DateTime.now()
            // Changed to today from + 31 days
            // .plus({ days: 31 })
            .toUTC()
            .startOf('day')
            .toISO(),
         ...defaultValues,
      },
   });

   const stateAbbr = form.watch('stateAbbr');
   const watchRelationshipCode = form.watch('relationshipCode');

   const isPrimarySubscriber =
      form.watch('relationshipCode') === ClaimMdPatientRelationshipCode.Self;

   const { insurances } = useInsurances({
      ...(typeof stateAbbr === 'string' ? { state: stateAbbr } : { state: '' }),
      enabled: Boolean(stateAbbr),
   });

   const insuranceOptions = insurances.map((insurance) => {
      return {
         label: insurance.name,
         value: insurance.payerId,
      };
   });

   const {
      register,
      handleSubmit,
      control,
      setValue,
      formState: { errors },
   } = form;

   const { mutate: getEligibility, isPending: isCheckingEligibility } =
      useGetInsuranceEligibility({
         onSuccess: (res, variables) => {
            return onResult?.(res, variables);
         },
         onError,
      });

   const onFormSubmit = (data: EligibilityFormData) => {
      const insuranceName = insuranceOptions.find(
         (i) => i.value === data.payerId,
      )?.label;

      const params = {
         ...data,
         insuranceName: insuranceName ?? '',
         insured: {
            ...data.insured,
            dob: data.insured.dob
               ? DateTime.fromISO(data.insured.dob).toUTC()
               : undefined,
         },
         formsortUUID: null,
      };

      const processedParams = onBeforeSubmit?.(params) ?? params;

      (onSubmit ?? getEligibility)(processedParams);
   };

   return (
      <form id="eligibility-form" onSubmit={handleSubmit(onFormSubmit)}>
         <CustomFormControl
            mode="dark"
            name="roles"
            label="Insurance company"
            isInvalid={!!errors.payerId}
            errorMsg={errors.payerId?.message}
         >
            <Controller
               name="payerId"
               control={control}
               render={({ field: { onChange, value, ref } }) => {
                  return (
                     <Select
                        id={`insuranceEncounters`}
                        size="lg"
                        ref={ref}
                        maxMenuHeight={200}
                        chakraStyles={chakraStyles}
                        options={insuranceOptions}
                        placeholder="Select your insurance"
                        value={insuranceOptions.find(
                           (opt) => opt?.value === value,
                        )}
                        onChange={(newValue) => {
                           if (!Array.isArray(newValue) && newValue) {
                              const singleValue =
                                 newValue as SingleValue<Option>;
                              if (singleValue) {
                                 onChange(singleValue?.value);
                              }
                           } else {
                              onChange('');
                           }
                        }}
                        filterOption={(option: Option, inputValue: string) => {
                           return (
                              option?.label
                                 .toLowerCase()
                                 .includes(inputValue.toLowerCase()) ?? false
                           );
                        }}
                        useBasicStyles
                     />
                  );
               }}
            />
         </CustomFormControl>

         {insuranceNotCoveredButton && (
            <Button
               variant="link"
               color="background.primary"
               fontWeight={600}
               fontSize={{ base: 'xs', md: 'xs' }}
               mb={3}
               {...insuranceNotCoveredButton}
            >
               {`I don't see my insurance listed`}
            </Button>
         )}

         <Divider borderColor="background.primary" opacity={0.15} my={3} />

         <Flex gap={{ lg: 5 }} flexDir={{ base: 'column', lg: 'row' }}>
            <CustomFormControl
               mode="dark"
               label="Patient legal first name"
               name="insurance-first-name"
               isInvalid={!!errors.insured?.firstName}
               errorMsg={
                  errors.insured?.firstName && errors.insured?.firstName.message
               }
            >
               <Input
                  id="insurance-first-name"
                  {...register('insured.firstName')}
                  placeholder="Patient legal first name"
                  bg="white"
               />
            </CustomFormControl>

            <CustomFormControl
               mode="dark"
               label="Patient legal last name"
               name="insurance-last-name"
               isInvalid={!!errors.insured?.lastName}
               errorMsg={
                  errors.insured?.lastName && errors.insured?.lastName.message
               }
            >
               <Input
                  bg="white"
                  id="insurance-last-name"
                  {...register('insured.lastName')}
                  placeholder="Patient legal last name"
               />
            </CustomFormControl>
         </Flex>

         <Flex gap={{ lg: 5 }} flexDir={{ base: 'column', lg: 'row' }}>
            <CustomFormControl
               mode="dark"
               label="Date of birth"
               name="insurance-dob"
               isInvalid={!!errors.insured?.dob}
               errorMsg={errors.insured?.dob && errors.insured?.dob.message}
               helperText="(mm/dd/yyyy)"
            >
               <Input
                  bg="white"
                  type="tel"
                  as={InputMask}
                  mask="**/**/****"
                  maskChar="_"
                  id="insurance-dob"
                  {...register('insured.dob')}
                  placeholder="Enter your date of birth"
               />
            </CustomFormControl>

            <CustomFormControl
               mode="dark"
               label="Member ID"
               tooltipText={`Your Member ID is located on the front of your
                        insurance card. Look for where it says "ID" and use the
                        number shown to the right.`}
               name="insurance-member-id"
               isInvalid={!!errors.insured?.memberId}
               errorMsg={
                  errors.insured?.memberId && errors.insured?.memberId.message
               }
            >
               <Input
                  bg="white"
                  id="insurance-member-id"
                  {...register('insured.memberId')}
                  placeholder="Member ID"
               />
            </CustomFormControl>
         </Flex>

         <CustomFormControl
            name="relationshipCode"
            py={3}
            isInvalid={!!errors.relationshipCode}
            errorMsg={
               errors.relationshipCode && errors.relationshipCode.message
            }
         >
            <CustomCheckbox
               variant="dark"
               checked={isPrimarySubscriber}
               isChecked={isPrimarySubscriber}
               value={watchRelationshipCode}
               onChange={(e) => {
                  setValue(
                     'relationshipCode',
                     e.currentTarget.checked
                        ? ClaimMdPatientRelationshipCode.Self
                        : ClaimMdPatientRelationshipCode.Dependent,
                  );
               }}
            >
               Patient is the primary subscriber
            </CustomCheckbox>
         </CustomFormControl>

         {FooterRow ? (
            <FooterRow isLoading={isCheckingEligibility} />
         ) : (
            <Flex>
               <Button
                  type="submit"
                  isLoading={isCheckingEligibility || isDisabled}
                  ml="auto"
               >
                  Check
               </Button>
            </Flex>
         )}
      </form>
   );
};
